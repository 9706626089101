html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: rgb(245, 245, 245) !important;
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

#__next {
    height: 100%;
}

.MuiDropzoneArea-root {
    min-height: 190px !important;
}

.MuiListItemIcon-root {
    min-width: 40px !important;
}

.capitalize {
    text-transform: capitalize;
}
